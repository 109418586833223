import axios from 'axios';
import callAPI from '../../configs/axios';
import appConfig from "../../configs/appConfig";

export async function _getDataInvoices_Report(id_so) {
    const URL = `${process.env.API_GATEWAY}/oci/orders/invoice/${id_so}`;
    const response = await axios.get(URL, {
        headers: {
            Xemail: `wahdangun@gmail.com`,
            key: `ocisuperkeren`,
        },
    });
    const axiosResponse = response.data;
   
    return axiosResponse;

}

export async function getDataInvoices_Report(id_so) {
    const url = `${process.env.API_GATEWAY}/oci/orders/invoice/${id_so}`;
  
    return callAPI({
      url,
      method: 'GET',
      token: true,
    });
  }

export async function getCekResi(data) {
    const hostname = window && window.location && window.location.hostname;
    let LOMILES_API = "";
    //check undefined ….
    if (hostname.indexOf("localhost") >= 0) {
      LOMILES_API = appConfig.LOMILES_API_LOCAL;
    }else if (hostname.indexOf(".com") >= 0) {
      LOMILES_API = appConfig.LOMILES_API_PROD;
    }
    const url = `${LOMILES_API}/api/v1/cek-resi`;

    return callAPI({
    url,
    method: 'POST',
    token: true,
    data
    });
}