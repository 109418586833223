import React from 'react';
// import ReactDOM from'react-dom';
import ReactDOM from "react-dom/client";

import Route from './routes/Route';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
      <Route />
    </React.StrictMode>
  );
// ReactDOM.render(<Route/>, document.getElementById('root'));
