import React from "react";
import { useRef, useState, useMediaQuery, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../frontend/layanan/layanan.css";
import { Brand, CTA, Navbar } from "../../../components";
import { FiturGalery, Footer, Header } from "../../../containers";
import { getData_Master_Categories } from "../../../constants/api/logistik";
import { getData_Master_Jenisbarang } from "../../../constants/api/logistik";
import env from "react-dotenv";
import swal from "sweetalert";
import appConfig from "../../../configs/appConfig";

//fungsi kalkulasi

function useScreenWidth() {
  const [windowWidth, setWindowWidth] = useState(null);

  const isWindow = typeof window !== "undefined";

  const getWidth = () => (isWindow ? window.innerWidth : windowWidth);

  const resize = () => setWindowWidth(getWidth());

  useEffect(() => {
    if (isWindow) {
      setWindowWidth(getWidth());

      window.addEventListener("resize", resize);

      return () => window.removeEventListener("resize", resize);
    }
    //eslint-disable-next-line
  }, [isWindow]);

  return windowWidth;
}

const Layanan = () => {
  const [dataCategories, setDataCategories] = useState(null);
  const [dataJenisBarang, setDataJenisBarang] = useState(null);

  //use state input request from form input
  const [inputkategori, setKategori] = useState("");
  const [inputnamabarang, setNamabarang] = useState("");
  const [inputberatbarang, setBeratbarang] = useState("");
  const [inputpanjang, setPanjang] = useState("");
  const [inputlebar, setLebar] = useState("");
  const [inputtinggi, setTinggi] = useState("");
  const [inputvolume, setVolume] = useState("");
  const kategori_select = useRef("");
  const kategori_select_air = useRef("");
  const [deskripsi_kategori, setDeskripsiKategori] = useState(null);
  const [deskripsi_kategori_Air, setDeskripsiKategori_Air] = useState(null);

  const [TotalestimasibiayaLaut, setEstimasiBiayaLaut] = useState("");
  const [TotalestimasibiayaUdara, setEstimasiBiayaUdara] = useState("");
  const volume_total = inputvolume;

  const inputRef_laut = useRef(null);
  const inputRef_udara = useRef(null);
  const inputRef_darat = useRef(null);
  const [updated, setUpdated] = useState("");
  const warna_choice_aktif =
    "linear-gradient(180deg, #62C7FF 0%, #327CA5 100%)";
  const warna_choice_aktif_child = "#008BD9";
  const warna_choice_tidak_aktif = "white";
  const warna_choice_tidak_aktif_child = "white";
  const display_aktif = "block";
  const display__tidak_aktif = "none";

  const widthSize = useScreenWidth();

  console.log("ukuran layar live:", widthSize);

  console.log("updated klik:", updated);

  useEffect(() => {
    if (!dataCategories) {
      getDataMasterCategory();
    }

    if (!dataJenisBarang) {
      getDataMasterJenisBarang();
    }
  }, [dataCategories, dataJenisBarang]);

  const input_kategori = kategori_select.current.value;
  const input_kategori_air = kategori_select_air.current.value;

  //console.log("data jenis barang:",dataJenisBarang)

  const deskripsibypilih = [];
  const deskripsibypilih_air = [];

  for (let j = 0; j < dataJenisBarang?.length; j++) {
    //console.log("input kategori :",input_kategori)

    //console.log("data kategori all:",dataJenisBarang)

    if (dataJenisBarang[j].kategori_barang == input_kategori) {
      deskripsibypilih.push(dataJenisBarang[j].detail_barang);

      // console.log("input kategori :",dataJenisBarang[j])
    }

    if (dataJenisBarang[j].kategori_barang == input_kategori_air) {
      deskripsibypilih_air.push(dataJenisBarang[j].detail_barang);

      // console.log("input kategori :",dataJenisBarang[j])
    }
  }

  const deskripsi_barang = deskripsibypilih;
  const deskripsi_barang_air = deskripsibypilih_air;

  const getDataMasterCategory = async () => {
    const res = await getData_Master_Categories();
    if (res.status === 200) {
      setDataCategories(res.data);
    }
  };

  const getDataMasterJenisBarang = async () => {
    const res = await getData_Master_Jenisbarang();
    if (res.status === 200) {
      setDataJenisBarang(res.data);
    }
  };

  //fungsi kalkulasi biaya

  const handleGetHitungBiayaClick = async (e) => {
    e.preventDefault();
    var display_click_lcl_sea = "block";

    const hostname = window && window.location && window.location.hostname;
      let LOMILES_API = "";
      //check undefined ….
      if (hostname.indexOf("localhost") >= 0) {
        LOMILES_API = appConfig.LOMILES_API_LOCAL;
      }else if (hostname.indexOf(".com") >= 0) {
        LOMILES_API = appConfig.LOMILES_API_PROD;
      }

    const url = `${LOMILES_API}/api/v1/create-lcl-by-sea`;

    if (inputberatbarang == "" && inputkategori == "" && volume_total == "") {
      swal({
        title: "Form Input Tidak Diisi atau Tidak Lengkap?",
        text: "Pastikan untuk mengisi form dengan lengkap",
        icon: "warning",
        dangerMode: true,
      });
    } else if (
      inputberatbarang == "" ||
      inputkategori == "" ||
      volume_total == ""
    ) {
      swal({
        title: "Form Input Tidak Diisi atau Tidak Lengkap?",
        text: "Pastikan untuk mengisi form dengan lengkap",
        icon: "warning",
        dangerMode: true,
      });
    } else {
      try {
        let res = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            volume: parseInt(volume_total),
            weight: parseInt(inputberatbarang),
            category: inputkategori,
            is_airplane: false,
          }),
        });
        let resJson = await res.json();
        if (res.status === 200) {
          console.log(resJson);
          // console.log(JSON.stringify(resJson.data.Laut)

          const data_total_Laut = resJson.data;

          //console.log(display_click_lcl_sea);

          //laut
          setEstimasiBiayaLaut(data_total_Laut);
        } else {
          console.log("error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleGetHitungBiayaUdaraClick = async (e) => {
    e.preventDefault();

    var display_click_lcl_udara = "block";

    const hostname = window && window.location && window.location.hostname;
      let LOMILES_API = "";
      //check undefined ….
      if (hostname.indexOf("localhost") >= 0) {
        LOMILES_API = appConfig.LOMILES_API_LOCAL;
      }else if (hostname.indexOf(".com") >= 0) {
        LOMILES_API = appConfig.LOMILES_API_PROD;
      }

    const url = `${LOMILES_API}/api/v1/create-lcl-by-sea`;

    if (inputberatbarang == "" && inputkategori == "" && volume_total == "") {
      swal({
        title: "Form Input Tidak Diisi atau Tidak Lengkap?",
        text: "Pastikan untuk mengisi form dengan lengkap",
        icon: "warning",
        dangerMode: true,
      });
    } else if (
      inputberatbarang == "" ||
      inputkategori == "" ||
      volume_total == ""
    ) {
      swal({
        title: "Form Input Tidak Diisi atau Tidak Lengkap?",
        text: "Pastikan untuk mengisi form dengan lengkap",
        icon: "warning",
        dangerMode: true,
      });
    } else {
      try {
        let res = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            volume: parseInt(volume_total),
            weight: parseInt(inputberatbarang),
            category: inputkategori,
            is_airplane: true,
          }),
        });
        let resJson = await res.json();
        if (res.status === 200) {
          console.log(resJson);

          const data_total_Udara = resJson.data;

          setEstimasiBiayaUdara(data_total_Udara);
        } else {
          console.log("error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const TotalbiayaLaut = TotalestimasibiayaLaut;

  const TotalbiayaUdara = TotalestimasibiayaUdara;

  const mobileWidth = 500;

  if (widthSize > mobileWidth) {
    //logic for desktop

    if (updated == "darat") {
      var display_margin = "370px";
      var display_height_form = "960px";
    }

    if (updated == "udara") {
      // var display_margin='430px';
      // var display_height_form='960px';

      var display_margin = "360px";
      var display_height_form = "750px";
      var sectionmobile = "220vh";
    } else {
      var display_margin = "340px";
      var display_height_form = "750px";
      var sectionmobile = "220vh";
    }

    //jika select kategori

    if (input_kategori > "0") {
      var display_margin = "440px";
      var display_height_form = "750px";
      var sectionmobile = "180vh";
    }
  }

  if (widthSize <= mobileWidth) {
    //logic for mobile

    // jika itu adalah pengiriman darat mobile version

    if (updated == "laut") {
      var display_margin = "460px";
      var display_height_form = "730px";
      var sectionmobile = "150vh";
    } else {
      var display_margin = "380px";
      var display_height_form = "700px";
      var sectionmobile = "150vh";
    }

    if (input_kategori > "0") {
      var display_margin = "480px";
      var display_height_form = "580px";
    }

    if (input_kategori == "6402d86f190408cf0424f502") {
      var display_margin = "400px";
      var display_height_form = "580px";
    }

    if (input_kategori == "0") {
      var display_margin = "390px";
      var display_height_form = "440px";
    }
  }

  const onClick_darat = () => {
    setUpdated(inputRef_darat.current.value);

    console.log("lihat data click  :", updated);
  };

  const onClick_udara = () => {
    setUpdated(inputRef_udara.current.value);

    console.log("lihat data click  :", updated);
  };

  const onClick_laut = () => {
    setUpdated(inputRef_laut.current.value);

    console.log("lihat data click  :", updated);
  };

  //kondisional fungsi ketika dipilih

  if (updated == "laut") {
    var ubahwarna_utama_laut = warna_choice_aktif;
    var ubahwarna_utama_udara = "white";
    var ubahwarna_child_laut = warna_choice_aktif_child;
    var display_hidup_laut = display_aktif;
    var ubahwarna_font_laut = "white";
    var ubahwarna_font_udara = "#0F8ED6";
  } else {
    var display_hidup_laut = display__tidak_aktif;
  }

  if (updated == "darat") {
    var ubahwarna_utama_darat = warna_choice_aktif;
    var ubahwarna_child_darat = warna_choice_aktif_child;

    // var display_margin='400px';
    // var display_height_form='520px';

    var display_hidup_darat = display_aktif;
  } else {
    var display_hidup_darat = display__tidak_aktif;
  }

  if (updated == "udara") {
    var ubahwarna_utama_udara = warna_choice_aktif;
    var ubahwarna_utama_laut = "white";
    var ubahwarna_child_udara = warna_choice_aktif_child;
    var display_hidup_udara = display_aktif;
    var ubahwarna_font_udara = "white";
    var ubahwarna_font_laut = "#0F8ED6";
  } else {
    var display_hidup_udara = display__tidak_aktif;
  }

  //jika updated kosong

  if (updated == "") {
    var ubahwarna_utama_laut = warna_choice_aktif;
    var ubahwarna_utama_udara = "white";
    var ubahwarna_child_laut = warna_choice_aktif_child;
    var display_hidup_laut = display_aktif;
    var ubahwarna_font_udara = "#0F8ED6";
  }

  //batas kondisional

  function allowNumbersOnly(e) {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }

  return (
    <div className="App">
      <div className="gradient__bg_layanan">
        <Navbar />
        <div className="gpt3__header section__padding" id="home">
          <div className="gpt3__header-content">
            <h1 className="gradient__text" style={{ marginTop: "-100px" }}>
              LAYANAN
            </h1>
          </div>

          {/* tombol pilihan  */}
          
        </div>
        <FiturGalery/>
        {/* <Footer/> */}
      </div>
    </div>
  );
};

export default Layanan;
