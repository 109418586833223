import React from "react";
import gpt3Logo from "../../assets/logo.svg";
import logo from "../../assets/image/LOMILES_PUTIH.png";

import "./footer.css";
const Footer = () => {
  return (
    <>
      <div className="footer_screen">
        <div className="gpt3__footer section__padding bg-footer-blue">
          <div className="gpt3__footer-heading">
            <h1 className="gradient__text"></h1>
          </div>

          <div className="footer-nama-perusahaan">
            <div className="col-md-2 footer-logo" style={{ width: "220px" }}>
              <img src={logo} style={{ width: "139%" }} alt="logo" />
              <p></p>
            </div>

            <div className="container-footer-nama-perusahaan">
              <div className="col-md-3 mb-footer desc-footer">
                <div className="text-white mb-2 title-footer">Akun Saya</div>
                <div className="text-white">Keranjang</div>
                <div className="text-white">Hubungi Kami</div>
              </div>

              <div className="col-md-3 mb-footer">
                <div className="text-white mb-2 title-footer">Tentang Kami</div>
                <div className="desc-footer">
                  <div className="text-white">Syarat Ketentuan</div>
                  <div className="text-white">Kebijakan Refund</div>
                  <div className="text-white">Kebijakan Privasi</div>
                  <div className="text-white">Tentang Kami</div>
                  <div className="text-white">Blogs</div>
                  <div className="text-white">FAQ</div>
                </div>
              </div>

              <div className="col-md-4 mb-footer">
                <div className="text-white mb-2 title-footer">Lokasi Kami</div>
                <div className="desc-footer">
                  Gedung Wisma IWI Jl. Arjuna Sel. No.75, Lt. 7, RT.2/RW.12, Kb.
                  Jeruk, Kec. Kb. Jeruk, Kota Jakarta Barat, Daerah Khusus
                  Ibukota Jakarta 11530 021-50867088 info@lomiles.com
                </div>
              </div>
            </div>
          </div>

          <div className="title-mediasosial-footer">
            Follow Akun Media Sosial Kita
          </div>
          <div className="footer-media-2">
            <div className="footer-media-sosial">
              <div className="col-md-2">
                <img
                  className="media-social-img"
                  src="/image/group270.png"
                ></img>
              </div>
              <div className="col-md-2">
                <img
                  className="media-social-img"
                  src="/image/group269.png"
                ></img>
              </div>
              <div className="col-md-2">
                <img
                  className="media-social-img"
                  src="/image/group268.png"
                ></img>
              </div>
              <div className="col-md-2">
                <img
                  className="media-social-img"
                  src="/image/group267.png"
                ></img>
              </div>
              <div className="col-md-2">
                <img
                  className="media-social-img"
                  src="/image/group266.png"
                ></img>
              </div>
              <div className="col-md-2">
                <img
                  src="/image/group265.png"
                  className="media-social-img"
                ></img>
              </div>
            </div>
          </div>

          <center>
            <div
              className="fontkonsumen mt-4"
              style={{ fontSize: "13px", fontWeight: "normal" }}
            >
              Layanan Pengaduan Konsumen
              <div>
                <div>PT. Ocommerce Capital Indonesia</div>
                <div>Email: info@lomiles.com</div>
                <div>
                  Direktorat Jenderal Perlindungan Konsumen dan Tertib Niaga
                </div>
                <div>Kementerian Perdagangan Republik Indonesia</div>
                <div>Whatsapp Ditjen PKTN: 0853 1111 1010</div>
              </div>
            </div>
            <div className="row"></div>
          </center>

          <div></div>
        </div>
      </div>
      <section className="">
        <div className="gpt3__footer-copyright text-dark">
          Copyright © PT Ocommerce Capital Indonesia.
        </div>
      </section>
    </>
  );
};
export default Footer;
