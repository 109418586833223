import React from "react";
import "./header.css";
import people from "../../assets/people.png";
import ai from "../../assets/ai.png";
const Header = () => {
  return (
    <div className="gpt3__header" id="home">
      <div className="container-logistic" >
        <h2 className="logistic-title">Logistic Channel China - Indonesia</h2>
        <div className="container-hubungikami">
          <a
            className="btn-header header"
            href="/kontak"
          >
            <p className="text-hubungikami">Hubungi Kami</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
